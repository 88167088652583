export var multiply = function(arg1, arg2) { // 乘法
  if (arg1 && arg2) {
    var m = 0;
    var s1 = arg1.toString();
    var s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) { console.log(e); }
    try {
      m += s2.split('.')[1].length;
    } catch (e) { console.log(e); }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
  } else {
    return '';
  }
};

export var addition = function(arg1, arg2) { // 加法
  var r1, r2, m;
  try { r1 = arg1.toString().split('.')[1].length; } catch (e) { r1 = 0; }
  try { r2 = arg2.toString().split('.')[1].length; } catch (e) { r2 = 0; }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
};
