<template>
  <div class="ServiceDetails">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/home')">首页</span> > <span class="active" @click="goto('/freightRate')">运价查询 - </span><span class="active">订单详情</span>
      </p>
    </div>
    <div class="c-box mt30">
      <el-row>
        <el-col :span="8">
          <div style="min-height:400px;background:#ccc">
            <img :src="forData.picUrl" style="width:100%">
          </div>
          <div style="margin-top:18px;margin-bottom:20px">
            <div style="width:80px;height:80px;background:#ccc;display:inline-block;margin-right:18px" />
            <div style="width:80px;height:80px;background:#ccc;display:inline-block;margin-right:18px" />
            <div style="width:80px;height:80px;background:#ccc;display:inline-block;margin-right:18px" />
          </div>
        </el-col>
        <el-col :span="16">
          <div class="content">
            <h3>{{ forData.names }}</h3>
            <div>
              <p class="money"><span class="tit">价格：</span><span>{{ forData.amountRange }}</span></p>
            </div>
            <el-col :span="12" class="content-p">
              <p>起 运 港：<span style="font-weight:bold;">{{ forData.startShipmentName }}</span></p>
              <p>航 程：<span v-show="forData.voyageDay" style="font-weight:bold;">{{ forData.voyageDay }}天</span></p>
              <p>开船日期：<span style="font-weight:bold;">{{ forData.sailTime }}</span></p>
            </el-col>
            <el-col :span="12" class="content-p">
              <p>目 的 港：<span style="font-weight:bold;">{{ forData.destinationPortName }}</span></p>
              <p>货物类型：<span v-show="forData.gidtype==1" style="font-weight:bold;">普货</span><span v-show="forData.gidtype==2" style="font-weight:bold;">特货</span></p>
              <p>截单日期：<span style="font-weight:bold;">{{ forData.cutOffTime }}</span></p>
            </el-col>
            <el-form ref="form" :model="form" label-width="70px" class="form">
              <el-form-item label="定柜尺寸">
                <span v-for="(item,i) in forData.oceanFeeList" :key="i" :class="{'button':true,'select':money.size.id==item.id}" :title="item?item.specificationName:''" @click="setCode(item,'size')">{{ item?item.specificationName:'' }}</span>
              </el-form-item>
              <el-form-item label="集货仓库">
                <span v-for="(item,i) in forData.inlandFeeList" :key="i" :class="{'button':true,'select':money.land.id==item.id}" :title="item?item.specificationName:''" @click="setCode(item,'land')">{{ item?item.specificationName:'' }}</span>
              </el-form-item>
              <el-form-item label="预计金额">
                <el-col :span="12">
                  <p class="money" style="margin:0;min-height:1px"><span class="money">￥<i>{{ payable }}</i></span></p>
                </el-col>
                <el-col :span="12">
                  <el-button type="primary" class="bhe50" icon="el-icon-message" style="min-width:220px;background:#FC6C3F;color:#fff;border-color:#FC6C3F" @click="goto('/freightRate/goOrder?id='+$route.query.id)">去下单</el-button>
                  <p style="margin:0;color:#FC6C3F;font-size:12px;line-height:25px">注意：不包含其他费用，可通过下单界面查看</p>
                </el-col>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="c-box" style="font-size:14px;margin-bottom:40px;">
      <p class="iconp">
        <span :class="{'ication':visable==1}" @click="visable=1">服务详情</span>
        <span :class="{'ication':visable==2}" @click="visable=2">费用明细</span>
        <span :class="{'ication':visable==3}" @click="visable=3">操作说明</span>
      </p>
      <div v-show="visable==1">
        <el-row>
          <p>{{ forData.names }}</p>
          <el-col :span="6">
            <p>起 运 港：{{ forData.startShipmentName }}</p>
          </el-col>
          <el-col :span="6">
            <p>目 的 港：{{ forData.destinationPortName }}</p>
          </el-col>
          <el-col :span="6">
            <p>订柜尺寸：<span v-for="(item,i) in forData.oceanFeeList" :key="i" :title="item?item.specificationName:''"> {{ item?item.specificationName:'' }}</span></p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <p>开船日期：{{ forData.sailTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截单日期：{{ forData.cutOffTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截仓日期：{{ forData.jcTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截亏仓日期：{{ forData.jkcTime }}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <p>航 程：<span v-show="forData.voyageDay" style="font-weight:bold;">{{ forData.voyageDay }}天</span></p>
          </el-col>
          <el-col :span="6">
            <p>货物类型：<span v-show="forData.gidtype==1" style="font-weight:bold;">普货</span><span v-show="forData.gidtype==2" style="font-weight:bold;">特货</span></p>
          </el-col>
          <el-col :span="6">
            <p>目的地仓库：<span v-for="(item,i) in forData.fabWarehouseVOList" :key="i" :title="item.warehouseName"> {{ item.warehouseName }}</span></p>
          </el-col>
        </el-row>
      </div>
      <div v-show="visable==2">
        <p>费用明细<span>仅供参考,实际费用请以最终账单为准</span></p>
        <el-table
          class="freightRate-table"
          :data="forData.oceanFeeList"
          style="width: 100%"
        >
          <el-table-column
            prop="costName"
            label="费用名称"
            width="180"
          />
          <el-table-column
            prop="amountFormat"
            label="金额"
            width="180"
          />
          <el-table-column
            prop="currencyCode"
            label="币种"
          />
          <el-table-column
            prop="remarks"
            label="备注"
          />
        </el-table>
      </div>
      <div v-show="visable==3" class="operation">
        <div class="title-ul">
          <p v-for="(item,i) in forData.remarksList" :key="i">{{ item }}</p>
          <!-- <p v-for="(item,i) in forData.remarksList" :key="i"><i></i>{{item}}</p> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { InfoFare } from '@/api/freight.js';
import { addition } from '@/utils/tools.js';
export default {
  name: 'ServiceDetails',
  props: {

  },
  data: function() {
    return {
      forData: {}, // 详情
      form: {},
      tableData: [],
      visable: 1,
      money: { size: {}, land: {}}
    };
  },
  computed: {
    payable: function() {
      let a, b;
      if (this.money.size.amount) {
        a = this.money.size.amount;
      } else {
        a = 0;
      }
      if (this.money.land.amount) {
        b = this.money.land.amount;
      } else {
        b = 0;
      }
      return addition(a, b);
    }
  },
  watch: {

  },
  created() {
    this.init();
  },
  mounted() {

  },
  methods: {
    init() { // 初始化
      if (this.$route.query.id) {
        this.getOreder();
      } else {
        this.$router.replace({ path: '/freightRate' });
      }
    },
    setCode(item, name) { // 选择尺寸 运输仓库
      this.money[name] = item;
      this.money = { ...this.money };
    },
    getOreder() {
      InfoFare({ id: this.$route.query.id }).then(result => {
        if (result.data.code == 0) {
          this.forData = result.data.data;
          this.money = {
            size: this.forData.oceanFeeList.length ? this.forData.oceanFeeList[0] : {},
            land: this.forData.inlandFeeList.length ? this.forData.inlandFeeList[0] : {}
          };
          console.log(this.money);
        }
        console.log(result);
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};

</script>

<style scoped>
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title .active{color:#507EFF}
.mt30{margin-top:30px}
.money{font-size: 24px;font-weight: bold;margin-right: 10px;}
.operation .title{font-weight:bold;}
.operation p{line-height:35px}
.operation i{display:inline-block;width:6px;height:6px;margin-right:13px;border-radius:3px;background:#507EFF;}
.content{margin-left:30px;font-size:14px}
.content h3{margin:0;}
.money{color:#FE3E1F;font-size:16px;font-weight: normal;margin:40px 0;}
.money span{font-size:16px}
.money i{font-size:24px;font-style: inherit;}
.money .tit{color: #333;font-size:14px}
.content-p p{margin:0;margin-bottom:40px}
.iconp{font-size:12px;height:40px;line-height:40px;border-bottom:1px solid #ccc;}
.iconp span{min-width:160px;display:inline-block;text-align:center;cursor:pointer;}
.ication{border-bottom: 3px solid #5878d0;position:relative;}
.ication:after{content: "";display: inline-block;position: absolute;bottom: 0;border-top: 0;border-right: 10px solid #ffffff;border-bottom: 5px solid #5878d0;border-left: 10px solid white;left: 70px;}
.operation{margin-left: 55px;}
.operation .title{font-weight:bold;margin:20px 0 10px;;background:none;color:#333;font-size:14px}
.title-ul p{margin:0;}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
</style>
